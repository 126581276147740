/* global BigInt */
import React, { useState, useEffect } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { ComputeBudgetProgram, PublicKey, Transaction, TransactionInstruction, TransactionMessage, VersionedTransaction, SystemProgram, SYSVAR_RENT_PUBKEY } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from '@solana/spl-token';
import BN from 'bn.js';
import ErrorPopup from './ErrorPopup';

const OracleProgramSection = () => {
  const { connection } = useConnection();
  const { connected, publicKey, signTransaction } = useWallet();
  const [oracleName, setOracleName] = useState('');
  const [apiCalls, setApiCalls] = useState([{
    url: '',
    headers: '',
    maxRequestsPerWindow: 1,
    windowDurationSeconds: 60,
  }]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);
  const [registrationFee] = useState(250);
  const [transactionFee, setTransactionFee] = useState(0.000005);
  const [successMessage, setSuccessMessage] = useState(null);

  // Constants
  const PROGRAM_ID = new PublicKey('6MpwBJeZ76HxfsYeYKapBPEhCBbUZvSDwDNEqYCJNeXH');

  useEffect(() => {
    const checkUserStatus = async () => {
      if (connected && publicKey) {
        try {
          const registered = await checkIfUserIsRegisteredAsOracle(publicKey, connection);
          setIsRegistered(registered);
          const estimatedFee = await estimateTransactionFee();
          setTransactionFee(estimatedFee);
        } catch (error) {
          console.error('Error checking user status:', error);
          setErrorMessage('Failed to check your account status. Please try again.');
        }
      }
    };

    checkUserStatus();
  }, [connected, publicKey, connection]);

  const estimateTransactionFee = async () => {
    try {
      const { blockhash } = await connection.getLatestBlockhash();
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: publicKey,
          lamports: 0,
        })
      );
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;
      const fee = await transaction.getEstimatedFee(connection);
      return fee / 1e9; // Convert lamports to SOL
    } catch (error) {
      console.error('Error estimating transaction fee:', error);
      return 0.000005; // Default to a small fee if estimation fails
    }
  };

  const handleAddApiCall = () => {
    setApiCalls([...apiCalls, {
      url: '',
      headers: '',
      maxRequestsPerWindow: 1,
      windowDurationSeconds: 60,
    }]);
  };

  const handleApiCallChange = (index, field, value) => {
    const newApiCalls = [...apiCalls];
    newApiCalls[index][field] = value;
    setApiCalls(newApiCalls);
  };

  const validateApiCalls = () => {
    for (let call of apiCalls) {
      if (!isValidUrl(call.url)) {
        setErrorMessage('Invalid URL format in API call');
        return false;
      }
      if (call.headers && !isValidJson(call.headers)) {
        setErrorMessage('Invalid JSON format in headers');
        return false;
      }
      if (call.maxRequestsPerWindow < 1) {
        setErrorMessage('Max requests per window must be at least 1');
        return false;
      }
      if (call.windowDurationSeconds < 1) {
        setErrorMessage('Window duration must be at least 1 second');
        return false;
      }
    }
    return true;
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;  
    }
  };

  const isValidJson = (string) => {
    try {
      JSON.parse(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleCreateOracle = async (e) => {
    e.preventDefault();
    if (!publicKey || !signTransaction) {
      setErrorMessage('Wallet not connected or does not support transaction signing!');
      return;
    }
	
    if (!apiCalls || apiCalls.length === 0) {
	  setErrorMessage('At least one API call is required.');
	  return;
    }  
	  
    if (!validateApiCalls()) return;
    setIsLoading(true);
    setErrorMessage(null);

    try {
      const [tokenMintPDA] = await PublicKey.findProgramAddress(
        [Buffer.from('pda_token_mint')],
        PROGRAM_ID
      );
      const userTokenAccount = await getAssociatedTokenAddress(
        tokenMintPDA,
        publicKey
      );

      const seed = `stake_oracle_${oracleName}_${publicKey.toBase58()}`;
      const hash = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(seed));
      const seedPrefix = `so${Buffer.from(hash).slice(0, 15).toString('hex')}`;

      const [stakeAccountPDA] = await PublicKey.findProgramAddress(
        [Buffer.from('stake'), Buffer.from(seedPrefix)],
        PROGRAM_ID
      );

      const [customDataAccountPDA] = await PublicKey.findProgramAddress(
        [Buffer.from('data'), Buffer.from(seedPrefix)],
        PROGRAM_ID
      );

      const [oracleTokenMintPDA] = await PublicKey.findProgramAddress(
        [Buffer.from('oracle_token_mint'), Buffer.from(oracleName)],
        PROGRAM_ID
      );
      const [oracleTokenAccountPDA] = await PublicKey.findProgramAddress(
        [Buffer.from('oracle_token_account'), Buffer.from(oracleName)],
        PROGRAM_ID
      );

	// Construct the instruction data
	const instructionData = Buffer.alloc(2000); // Adjust size as needed
	let offset = 0;

	// Instruction index
	instructionData.writeUInt8(1, offset);
	offset += 1;

	// Stake type (32 bytes, null-padded)
	const stakeTypeBuffer = Buffer.from("stake_oracle".padEnd(32, '\0'));
	stakeTypeBuffer.copy(instructionData, offset);
	offset += 32;

	// Stake amount (u64)
	const stakeAmountBuffer = new BN(registrationFee).toArrayLike(Buffer, 'le', 8);
	stakeAmountBuffer.copy(instructionData, offset);
	offset += 8;

	// Oracle name
	const nameBuffer = Buffer.from(oracleName, 'utf8');
	instructionData.writeUInt32LE(nameBuffer.length, offset);
	offset += 4;
	nameBuffer.copy(instructionData, offset);
	offset += nameBuffer.length;

	// Provider
	publicKey.toBuffer().copy(instructionData, offset);
	offset += 32;

	// API calls
	instructionData.writeUInt32LE(apiCalls.length, offset);
	offset += 4;

	for (let call of apiCalls) {
	  // URL
	  const urlBuffer = Buffer.from(call.url, 'utf8');
	  instructionData.writeUInt32LE(urlBuffer.length, offset);
	  offset += 4;
	  urlBuffer.copy(instructionData, offset);
	  offset += urlBuffer.length;

	  // Headers
	  const headersBuffer = Buffer.from(call.headers, 'utf8');
	  instructionData.writeUInt32LE(headersBuffer.length, offset);
	  offset += 4;
	  headersBuffer.copy(instructionData, offset);
	  offset += headersBuffer.length;

	  // Max requests per window
	  instructionData.writeBigUInt64LE(BigInt(call.maxRequestsPerWindow), offset);
	  offset += 8;

	  // Window duration seconds
	  instructionData.writeBigUInt64LE(BigInt(call.windowDurationSeconds), offset);
	  offset += 8;
	}

	// Next call time (initialized to 0)
	instructionData.writeBigInt64LE(BigInt(0), offset);
	offset += 8;

	// Call interval
	instructionData.writeBigInt64LE(BigInt(Math.min(...apiCalls.map(call => call.windowDurationSeconds))), offset);
	offset += 8;

	console.log("Instruction index:", instructionData.slice(0, 1).toString('hex'));
	console.log("Stake type:", instructionData.slice(1, 33).toString('utf8').replace(/\0+$/, ''));
	console.log("Stake amount:", new BN(instructionData.slice(33, 41), 'le').toString());

	const nameLength = instructionData.readUInt32LE(41);
	console.log("Oracle name length:", nameLength);
	console.log("Oracle name:", instructionData.slice(45, 45 + nameLength).toString('utf8'));

	console.log("Provider:", new PublicKey(instructionData.slice(45 + nameLength, 77 + nameLength)).toBase58());

	const apiCallsLength = instructionData.readUInt32LE(77 + nameLength);
	console.log("Number of API calls:", apiCallsLength);

	// Log each API call
	let currentOffset = 81 + nameLength;
	for (let i = 0; i < apiCallsLength; i++) {
	  const urlLength = instructionData.readUInt32LE(currentOffset);
	  currentOffset += 4;
	  console.log(`API Call ${i + 1} URL:`, instructionData.slice(currentOffset, currentOffset + urlLength).toString('utf8'));
	  currentOffset += urlLength;

	  const headersLength = instructionData.readUInt32LE(currentOffset);
	  currentOffset += 4;
	  console.log(`API Call ${i + 1} Headers:`, instructionData.slice(currentOffset, currentOffset + headersLength).toString('utf8'));
	  currentOffset += headersLength;

	  console.log(`API Call ${i + 1} Max Requests:`, instructionData.readBigUInt64LE(currentOffset));
	  currentOffset += 8;

	  console.log(`API Call ${i + 1} Window Duration:`, instructionData.readBigUInt64LE(currentOffset));
	  currentOffset += 8;
	}

	console.log("Next call time:", instructionData.readBigInt64LE(currentOffset));
	currentOffset += 8;

	console.log("Call interval:", instructionData.readBigInt64LE(currentOffset));

	console.log("Instruction data:", instructionData.slice(0, offset).toString('hex'));
      const additionalComputeBudgetInstruction = ComputeBudgetProgram.setComputeUnitLimit({ 
        units: 400_000 
      });

      const mainInstruction = new TransactionInstruction({
        keys: [
          { pubkey: publicKey, isSigner: true, isWritable: true },
          { pubkey: userTokenAccount, isSigner: false, isWritable: true },
          { pubkey: stakeAccountPDA, isSigner: false, isWritable: true },
          { pubkey: customDataAccountPDA, isSigner: false, isWritable: true },
          { pubkey: tokenMintPDA, isSigner: false, isWritable: false },
          { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
          { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
          { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false },
          { pubkey: oracleTokenMintPDA, isSigner: false, isWritable: true },
          { pubkey: oracleTokenAccountPDA, isSigner: false, isWritable: true },
        ],
        programId: PROGRAM_ID,
        data: instructionData.slice(0, offset),
      });

      const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();

      const messageV0 = new TransactionMessage({
        payerKey: publicKey,
        recentBlockhash: blockhash,
        instructions: [additionalComputeBudgetInstruction, mainInstruction]
      }).compileToV0Message();

      const transaction = new VersionedTransaction(messageV0);

      let signedTransaction = await signTransaction(transaction);
      const signature = await connection.sendRawTransaction(signedTransaction.serialize(), {
        skipPreflight: false,
        preflightCommitment: 'confirmed'
      });

      const confirmationResult = await connection.confirmTransaction({ 
        signature, 
        blockhash, 
        lastValidBlockHeight 
      });

      if (confirmationResult.value.err) {
        throw new Error(`Transaction failed: ${JSON.stringify(confirmationResult.value.err)}`);
      }

      setSuccessMessage('Oracle Program created successfully!');
      setIsRegistered(true);
      localStorage.setItem(`oracleRegistered_${publicKey.toBase58()}`, 'true');
    } catch (error) {
      console.error('Error creating Oracle Program:', error);
      setErrorMessage(`Failed to create Oracle Program: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const checkIfUserIsRegisteredAsOracle = async (publicKey, connection) => {
    const seed = `stake_oracle_${publicKey.toBase58()}`;
    const hash = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(seed));
    const seedPrefix = `so${Buffer.from(hash).slice(0, 15).toString('hex')}`;

    const [stakeAccountPDA] = await PublicKey.findProgramAddress(
      [Buffer.from('stake'), Buffer.from(seedPrefix)],
      PROGRAM_ID
    );
  
    try {
      const accountInfo = await connection.getAccountInfo(stakeAccountPDA);
      return accountInfo !== null;
    } catch (error) {
      console.error('Error checking oracle registration status:', error);
      return false;
    }
  };

  return (
    <div className="col-span-4 lg:col-span-3 w-full">
      <div className="flex flex-col w-full max-w-[95vw] sm:max-w-[90vw] md:max-w-[80vw] lg:max-w-[70vw] mx-auto min-h-[40rem]">
        <div className="w-full h-fit rounded-3xl px-4 xs:px-5 py-5 sm:px-8 sm:py-9 shadow-lg border border-light-gray bg-white/20 text-white">
          <div className="flex flex-col gap-4 items-center">
            <h1 className="text-left w-full text-3xl font-medium pl-3 sm:pl-0 pt-1">Create Oracle Program</h1>
            
            {!isRegistered ? (
              <form onSubmit={handleCreateOracle} className="w-full flex flex-col gap-4 mt-4 items-center">
                <div className="flex flex-col w-full">
                  <label className="text-sm text-white/70 mb-1">Oracle Program Name</label>
                  <input 
                    className="w-full p-2 rounded-xl bg-white/10 border border-white/40 text-white"
                    type="text"
                    value={oracleName}
                    onChange={(e) => setOracleName(e.target.value)}
                    placeholder="Enter oracle program name"
                    required
                  />
                </div>

                {apiCalls.map((call, index) => (
                  <div key={index} className="w-full bg-white/10 rounded-xl p-4 mt-4">
                    <h3 className="text-lg font-medium mb-2">API Call #{index + 1}</h3>
                    <div className="flex flex-col gap-2">
                      <input
                        className="w-full p-2 rounded-xl bg-white/10 border border-white/40 text-white"
                        type="text"
                        value={call.url}
onChange={(e) => handleApiCallChange(index, 'url', e.target.value)}
                        placeholder="API URL"
                        required
                      />
                      <input
                        className="w-full p-2 rounded-xl bg-white/10 border border-white/40 text-white"
                        type="text"
                        value={call.headers}
                        onChange={(e) => handleApiCallChange(index, 'headers', e.target.value)}
                        placeholder="Headers (JSON format)"
                      />
                      <div className="flex gap-2">
                        <div className="w-1/2">
                          <label className="text-sm text-white/70 mb-1">Max Requests</label>
                          <input
                            className="w-full p-2 rounded-xl bg-white/10 border border-white/40 text-white"
                            type="number"
                            value={call.maxRequestsPerWindow}
                            onChange={(e) => handleApiCallChange(index, 'maxRequestsPerWindow', Number(e.target.value))}
                            min="1"
                            required
                          />
                        </div>
                        <div className="w-1/2">
                          <label className="text-sm text-white/70 mb-1">Window Duration (seconds)</label>
                          <input
                            className="w-full p-2 rounded-xl bg-white/10 border border-white/40 text-white"
                            type="number"
                            value={call.windowDurationSeconds}
                            onChange={(e) => handleApiCallChange(index, 'windowDurationSeconds', Number(e.target.value))}
                            min="1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <button
                  type="button"
                  onClick={handleAddApiCall}
                  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  Add Another API Call
                </button>

                <div className="w-full mt-8">
                  <div className="w-[100%] sm:w-[80%] md:w-[70%] lg:w-[60%] mx-auto">
                    {connected ? (
                      <button 
                        type="submit"
                        className="custom-wallet-adapter-button !bg-gradient-to-r !from-blue-500 !to-purple-500 !w-full !py-4 !px-6 !rounded-full !text-white !font-bold !text-xl !h-auto !transition-all !duration-200 hover:!from-blue-600 hover:!to-purple-600"
                        disabled={isLoading}
                      >
                        {isLoading ? 'Creating Oracle Program...' : 'Create Oracle Program'}
                      </button>
                    ) : (
                      <WalletMultiButton 
                        className="custom-wallet-adapter-button !bg-gradient-to-r !from-blue-500 !to-purple-500 !w-full !py-4 !px-6 !rounded-full !text-white !font-bold !text-xl !h-auto !transition-all !duration-200 hover:!from-blue-600 hover:!to-purple-600"
                      />
                    )}
                    
                    <div className="mt-4 text-sm text-white/80">
                      <div className="flex justify-between mb-2">
                        <span>Registration fee:</span>
                        <span>{registrationFee} SolOracle tokens</span>
                      </div>
                      <div className="flex justify-between mb-2">
                        <span>Estimated transaction fee:</span>
                        <span>{transactionFee.toFixed(6)} SOL</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className="w-full mt-4 text-center">
                <p className="text-xl font-medium">You have already created an Oracle Program.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {errorMessage && (
        <ErrorPopup 
          message={errorMessage} 
          onClose={() => setErrorMessage(null)} 
        />
      )}
      {successMessage && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg">
            <p className="text-green-600 font-bold">{successMessage}</p>
            <button 
              onClick={() => setSuccessMessage(null)}
              className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OracleProgramSection;