import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  PieChart, Pie, Cell
} from 'recharts';

const PortfolioSection = () => {
  const { publicKey } = useWallet();
  const [providerData, setProviderData] = useState(null);
  const [oraclePrograms, setOraclePrograms] = useState([]);

  useEffect(() => {
    // Fetch provider data and oracle programs
    // This should be replaced with actual API calls to your backend
    const fetchData = async () => {
      // Simulating API call
      setProviderData({
        participatingPrograms: 3,
        totalBalance: 1000,
        stakedAmount: 500,
        stakingEarnings: 50,
        totalEarnings: 200
      });

      setOraclePrograms([
        {
          name: "BTC/USD Oracle",
          earningsPerHour: 0.5,
          earningsPerDay: 12,
          earningsPerWeek: 84,
          providers: 10,
          callsMade: 1000
        },
        {
          name: "ETH/USD Oracle",
          earningsPerHour: 0.3,
          earningsPerDay: 7.2,
          earningsPerWeek: 50.4,
          providers: 8,
          callsMade: 800
        },
        {
          name: "SOL/USD Oracle",
          earningsPerHour: 0.2,
          earningsPerDay: 4.8,
          earningsPerWeek: 33.6,
          providers: 5,
          callsMade: 500
        }
      ]);
    };

    fetchData();
  }, [publicKey]);

  const earningsData = [
    { name: 'Mon', earnings: 20 },
    { name: 'Tue', earnings: 25 },
    { name: 'Wed', earnings: 30 },
    { name: 'Thu', earnings: 22 },
    { name: 'Fri', earnings: 28 },
    { name: 'Sat', earnings: 35 },
    { name: 'Sun', earnings: 32 },
  ];

  const stakingData = [
    { name: 'Staked', value: providerData?.stakedAmount || 0 },
    { name: 'Unstaked', value: (providerData?.totalBalance - providerData?.stakedAmount) || 0 },
  ];

  const COLORS = ['#0088FE', '#00C49F'];

  return (
    <div className="col-span-4 lg:col-span-3 w-full">
      <div className="flex flex-col w-full max-w-[95vw] sm:max-w-[90vw] md:max-w-[80vw] lg:max-w-[70vw] mx-auto min-h-[40rem]">
        <div className="w-full h-fit rounded-3xl px-4 xs:px-5 py-5 sm:px-8 sm:py-9 shadow-lg border border-light-gray bg-white/20 text-white">
          <h1 className="text-3xl font-bold mb-6">Provider Portfolio</h1>

          {providerData && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <div className="bg-white/10 rounded-xl p-4">
                <h2 className="text-xl font-semibold mb-2">Overview</h2>
                <p>Participating Programs: {providerData.participatingPrograms}</p>
                <p>Total Balance: {providerData.totalBalance} SOL</p>
                <p>Staked Amount: {providerData.stakedAmount} SOL</p>
                <p>Staking Earnings: {providerData.stakingEarnings} SOL</p>
                <p>Total Earnings: {providerData.totalEarnings} SOL</p>
              </div>
              <div className="bg-white/10 rounded-xl p-4">
                <h2 className="text-xl font-semibold mb-2">Staking Distribution</h2>
                <ResponsiveContainer width="100%" height={200}>
                  <PieChart>
                    <Pie
                      data={stakingData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {stakingData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Earnings Over Time</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={earningsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="earnings" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <h2 className="text-2xl font-semibold mb-4">Participating Oracle Programs</h2>
          {oraclePrograms.map((program, index) => (
            <div key={index} className="bg-white/10 rounded-xl p-4 mb-4">
              <h3 className="text-xl font-semibold mb-2">{program.name}</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p>Earnings per hour: {program.earningsPerHour} SOL</p>
                  <p>Earnings per day: {program.earningsPerDay} SOL</p>
                  <p>Earnings per week: {program.earningsPerWeek} SOL</p>
                </div>
                <div>
                  <p>Participating Providers: {program.providers}</p>
                  <p>Total Calls Made: {program.callsMade}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;