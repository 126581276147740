import React, { useState, useEffect } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { PublicKey, Transaction, TransactionInstruction, SystemProgram } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from '@solana/spl-token';
import BN from 'bn.js';
import ErrorPopup from './ErrorPopup';

const StakeUnstakeSection = () => {
  const { connected, publicKey, sendTransaction } = useWallet();
  const { connection } = useConnection();
  const [isStaking, setIsStaking] = useState(true);
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [transactionFee, setTransactionFee] = useState(0.000005);

  // Constants
  const PROGRAM_ID = new PublicKey('BMq7jLjWzcXD2DbEDUHEW4vHc4CCzrwedWB9N9a9F4zQ');

  useEffect(() => {
    const estimateTransactionFee = async () => {
      if (connected && publicKey) {
        try {
          const { blockhash } = await connection.getLatestBlockhash();
          const transaction = new Transaction().add(
            SystemProgram.transfer({
              fromPubkey: publicKey,
              toPubkey: publicKey,
              lamports: 0,
            })
          );
          transaction.recentBlockhash = blockhash;
          transaction.feePayer = publicKey;
          const fee = await transaction.getEstimatedFee(connection);
          setTransactionFee(fee / 1e9); // Convert lamports to SOL
        } catch (error) {
          console.error('Error estimating transaction fee:', error);
        }
      }
    };

    estimateTransactionFee();
  }, [connected, publicKey, connection]);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!publicKey) return;
    setIsLoading(true);
    setErrorMessage(null);

    try {
      const [tokenMintPDA] = await PublicKey.findProgramAddress(
        [Buffer.from('token_mint')],
        PROGRAM_ID
      );
      const userTokenAccount = await getAssociatedTokenAddress(
        tokenMintPDA,
        publicKey
      );

      const [stakeAccountPDA] = await PublicKey.findProgramAddress(
        [Buffer.from('stake'), publicKey.toBuffer()],
        PROGRAM_ID
      );

      const instruction = new TransactionInstruction({
        keys: [
          { pubkey: publicKey, isSigner: true, isWritable: true },
          { pubkey: userTokenAccount, isSigner: false, isWritable: true },
          { pubkey: stakeAccountPDA, isSigner: false, isWritable: true },
          { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        ],
        programId: PROGRAM_ID,
        data: Buffer.from([
          isStaking ? 5 : 6, // 5 for StakeTokens, 6 for UnstakeTokens
          ...new BN(parseFloat(amount) * 1e9).toArray('le', 8), // Convert to lamports
        ]),
      });

      const transaction = new Transaction().add(instruction);
      const signature = await sendTransaction(transaction, connection);

      await connection.confirmTransaction(signature, 'confirmed');
      console.log(`${isStaking ? 'Staked' : 'Unstaked'} successfully!`, signature);
      setAmount('');
    } catch (error) {
      console.error(`Error ${isStaking ? 'staking' : 'unstaking'}:`, error);
      setErrorMessage(`Failed to ${isStaking ? 'stake' : 'unstake'}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="col-span-4 lg:col-span-3 w-full">
      <div className="flex flex-col w-full max-w-[95vw] sm:max-w-[90vw] md:max-w-[80vw] lg:max-w-[70vw] mx-auto min-h-[40rem]">
        <div className="w-full h-fit rounded-3xl px-4 xs:px-5 py-5 sm:px-8 sm:py-9 shadow-lg border border-light-gray bg-white/20 text-white">
          <div className="flex flex-col gap-4 items-center">
            <h1 className="text-left w-full text-3xl font-medium pl-3 sm:pl-0 pt-1">
              {isStaking ? 'Stake' : 'Unstake'} SolOracles
            </h1>
            <div className="w-full">
              <div className="w-full min-w-[240px] pt-2">
                <div className="flex bg-white/10 text-white text-extralight rounded-full" role="tablist" aria-orientation="horizontal">
                  <button 
                    className={`w-full text-base leading-5 outline-none transition-colors font-medium m-2 px-2 sm:px-4 py-2 sm:py-3 rounded-full ${isStaking ? 'shadow bg-white/20' : 'hover:bg-white/10 text-white/70'}`}
                    onClick={() => setIsStaking(true)}
                  >
                    <div className="w-full flex justify-center items-center">
                      <div>Stake</div>
                      <div className="hidden sm:block ml-2">
                        <div className="inline-flex items-center rounded-full text-xs py-1 px-2 bg-green-500 text-white">≈ 7.46% APY</div>
                      </div>
                    </div>
                  </button>
                  <button 
                    className={`w-full text-base leading-5 outline-none transition-colors font-medium m-2 px-2 sm:px-4 py-2 sm:py-3 rounded-full ${!isStaking ? 'shadow bg-white/20' : 'hover:bg-white/10 text-white/70'}`}
                    onClick={() => setIsStaking(false)}
                  >
                    Unstake
                  </button>
                </div>
              </div>
              <div className="mt-4 w-full">
                <div className="min-h-[340px] md:min-h-[400px]">
                  <div className="flex flex-col items-center w-full">
                    <form onSubmit={handleSubmit} className="w-full flex flex-col gap-4 mt-4 items-center">
                      <div className="flex flex-col w-full">
                        <div className="flex justify-between mb-2">
                          <div className="text-xs text-white/70">Amount to {isStaking ? 'stake' : 'unstake'}</div>
                        </div>
                        <div className="flex items-center border border-white/40 rounded-xl w-full p-2 gap-2">
                          <div className="flex items-center flex-shrink-0">
                            <div className="flex h-12 w-32 items-center justify-between rounded-md border border-white/30 bg-white/10 px-3 text-sm font-medium">
                              <img alt="SolOracle" width="24" height="24" className="rounded-full" src="/path-to-soloracle-logo.png" />
                              <span className="ml-2 text-white">SolOracle</span>
                            </div>
                          </div>
                          <input 
                            className="flex-1 text-right bg-transparent border-0 outline-none text-2xl text-white"
                            placeholder="0.0" 
                            type="text" 
                            name="amount" 
                            value={amount}
                            onChange={handleAmountChange}
                          />
                        </div>
                      </div>

                      <div className="w-full mt-8">
                        <div className="w-[100%] sm:w-[80%] md:w-[70%] lg:w-[60%] mx-auto">
                          {connected ? (
                            <button 
                              type="submit"
                              className="custom-wallet-adapter-button !bg-gradient-to-r !from-blue-500 !to-purple-500 !w-full !py-4 !px-6 !rounded-full !text-white !font-bold !text-xl !h-auto !transition-all !duration-200 hover:!from-blue-600 hover:!to-purple-600"
                              disabled={isLoading}
                            >
                              {isLoading ? `${isStaking ? 'Staking' : 'Unstaking'}...` : `${isStaking ? 'Stake' : 'Unstake'} SolOracle`}
                            </button>
                          ) : (
                            <WalletMultiButton 
                              className="custom-wallet-adapter-button !bg-gradient-to-r !from-blue-500 !to-purple-500 !w-full !py-4 !px-6 !rounded-full !text-white !font-bold !text-xl !h-auto !transition-all !duration-200 hover:!from-blue-600 hover:!to-purple-600"
                            />
                          )}
                          
                          <div className="mt-4 text-sm text-white/80">
                            <div className="flex justify-between mb-2">
                              <span>Estimated transaction fee:</span>
                              <span>{transactionFee.toFixed(6)} SOL</span>
                            </div>
                            <div className="flex justify-between font-semibold">
                              <span>Total after fee:</span>
                              <span>
                                {amount 
                                  ? (parseFloat(amount) + transactionFee).toFixed(6) 
                                  : transactionFee.toFixed(6)} SOL
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errorMessage && (
        <ErrorPopup 
          message={errorMessage} 
          onClose={() => setErrorMessage(null)} 
        />
      )}
    </div>
  );
};

export default StakeUnstakeSection;