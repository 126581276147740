import React, { useState } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';

const GovernanceSection = () => {
  const [proposalDescription, setProposalDescription] = useState('');
  const [votingPeriod, setVotingPeriod] = useState('');
  const [proposals, setProposals] = useState([]);
  const { connected } = useWallet();

  const handleCreateProposal = (e) => {
    e.preventDefault();
    console.log(`Creating proposal: ${proposalDescription} with voting period ${votingPeriod} days`);
    // Here you would call the backend to create the proposal
  };

  const handleVote = (proposalId, approve) => {
    console.log(`Voting ${approve ? 'for' : 'against'} proposal ${proposalId}`);
    // Here you would call the backend to submit the vote
  };

  const handleExecuteProposal = (proposalId) => {
    console.log(`Executing proposal ${proposalId}`);
    // Here you would call the backend to execute the proposal
  };

  return (
    <div className="col-span-4 lg:col-span-3 w-full">
      <div className="flex flex-col w-full max-w-[95vw] sm:max-w-[90vw] md:max-w-[80vw] lg:max-w-[70vw] mx-auto min-h-[40rem]">
        <div className="w-full h-fit rounded-3xl px-4 xs:px-5 py-5 sm:px-8 sm:py-9 shadow-lg border border-light-gray bg-white/20 text-white">
          <div className="flex flex-col gap-4 items-center">
            <h1 className="text-left w-full text-3xl font-medium pl-3 sm:pl-0 pt-1">Governance</h1>
            <form onSubmit={handleCreateProposal} className="w-full flex flex-col gap-4 mt-4 items-center">
              <div className="flex flex-col w-full">
                <div className="flex justify-between mb-2">
                  <div className="text-xs text-white/70">Proposal Description</div>
                </div>
                <textarea 
                  className="w-full p-2 rounded-xl bg-white/10 border border-white/40 text-white"
                  value={proposalDescription}
                  onChange={(e) => setProposalDescription(e.target.value)}
                  placeholder="Enter proposal description"
                  rows="4"
                />
              </div>
              <div className="flex flex-col w-full">
                <div className="flex justify-between mb-2">
                  <div className="text-xs text-white/70">Voting Period (days)</div>
                </div>
                <input 
                  className="w-full p-2 rounded-xl bg-white/10 border border-white/40 text-white"
                  type="number"
                  value={votingPeriod}
                  onChange={(e) => setVotingPeriod(e.target.value)}
                  placeholder="Enter voting period in days"
                />
              </div>
              <div className="w-full mt-8">
                <div className="w-[100%] sm:w-[80%] md:w-[70%] lg:w-[60%] mx-auto">
                  <WalletMultiButton 
                    className="custom-wallet-adapter-button !bg-gradient-to-r !from-blue-500 !to-purple-500 !w-full !py-4 !px-6 !rounded-full !text-white !font-bold !text-xl !h-auto !transition-all !duration-200 hover:!from-blue-600 hover:!to-purple-600"
                  >
                    {connected ? 'Create Proposal' : 'Connect Wallet'}
                  </WalletMultiButton>
                </div>
              </div>
            </form>
            
            <div className="w-full mt-8">
              <h2 className="text-2xl font-medium mb-4">Active Proposals</h2>
              {proposals.map((proposal, index) => (
                <div key={index} className="bg-white/10 rounded-xl p-4 mb-4">
                  <p>{proposal.description}</p>
                  <div className="flex justify-between mt-4">
                    <button onClick={() => handleVote(proposal.id, true)} className="bg-green-500 text-white px-4 py-2 rounded">Vote For</button>
                    <button onClick={() => handleVote(proposal.id, false)} className="bg-red-500 text-white px-4 py-2 rounded">Vote Against</button>
                    <button onClick={() => handleExecuteProposal(proposal.id)} className="bg-blue-500 text-white px-4 py-2 rounded">Execute</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GovernanceSection;