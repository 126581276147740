import React from 'react';

const HeroSection = ({ activeSection, setActiveSection, renderComponent }) => {
  const sections = ['provider', 'oracle', 'stake', 'governance', 'portfolio'];

  return (
    <div className="w-full flex flex-col items-center gap-8 py-12 bg-gradient-to-r from-blue-500 to-yellow-400 text-white">
      <div className="flex gap-2">
        {sections.map((section) => (
          <div
            key={section}
            className={`flex justify-center text-center px-2 py-1 min-w-[75px] sm:min-w-[90px] lg:min-w-[120px] text-xs md:text-sm rounded-full border border-white text-white cursor-pointer ${
              activeSection === section ? 'bg-white/20' : 'hover:bg-white/10'
            }`}
            onClick={() => setActiveSection(section)}
          >
            {section.charAt(0).toUpperCase() + section.slice(1)}
          </div>
        ))}
      </div>

      <div className="text-center max-w-3xl">
        <h2 className="text-4xl sm:text-5xl font-bold mb-4">SolOracles Platform</h2>
        <p className="text-lg sm:text-xl">
          Decentralized oracle network on Solana blockchain, providing reliable and secure data for smart contracts.
        </p>
      </div>

      <div className="w-full max-w-4xl">
        {renderComponent()}
      </div>
    </div>
  );
};

export default HeroSection;