import React, { useState, useEffect } from 'react';

const PresaleTracker = () => {
  const [tokensSold, setTokensSold] = useState(0);
  const [totalTokens] = useState(100000); // Total tokens available in pre-sale

  useEffect(() => {
    const fetchTokensSold = async () => {
      try {
        const response = await fetch('https://soloracles.xyz/api/presale-status/');
        const data = await response.json();
        if (data.status === 'success') {
          setTokensSold(data.tokens_sold);
        }
      } catch (error) {
        console.error('Error fetching pre-sale status:', error);
      }
    };

    fetchTokensSold();
    const interval = setInterval(fetchTokensSold, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  const progressPercentage = (tokensSold / totalTokens) * 100;

  return (
    <div className="bg-white/10 text-white rounded-lg p-4 shadow-md mx-auto max-w-4xl mt-4">
      <h3 className="text-lg font-semibold mb-2">Pre-sale Progress</h3>
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div
          className="bg-blue-600 h-2.5 rounded-full"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <p className="mt-2">
        {tokensSold.toLocaleString()} / {totalTokens.toLocaleString()} SolOracles Tokens Sold
      </p>
    </div>
  );
};

export default PresaleTracker;