import React, { useState, useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter, TorusWalletAdapter, LedgerWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import '@solana/wallet-adapter-react-ui/styles.css';
import Navbar from './Navbar';
import HeroSection from './HeroSection';
import ProviderSection from './ProviderSection';
import OracleProgramSection from './OracleProgramSection';
import StakeUnstakeSection from './StakeUnstakeSection';
import GovernanceSection from './GovernanceSection';
import PortfolioSection from './PortfolioSection';
import PresaleNotification from './PresaleNotification';
import PresaleTracker from './PresaleTracker';
import PresaleAccessOverlay from './PresaleAccessOverlay';

function App() {
  const [activeSection, setActiveSection] = useState('provider');
  const [showPresaleAccess, setShowPresaleAccess] = useState(false);
  
  // Explicitly set the network to testnet
  const network = WalletAdapterNetwork.Testnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [new PhantomWalletAdapter(), new SolflareWalletAdapter(), new TorusWalletAdapter(), new LedgerWalletAdapter()],
    []
  );

  const renderComponent = () => {
    switch (activeSection) {
      case 'provider':
        return <ProviderSection />;
      case 'oracle':
        return <OracleProgramSection />;
      case 'stake':
        return <StakeUnstakeSection />;
      case 'governance':
        return <GovernanceSection />;
      case 'portfolio':
        return <PortfolioSection />;
      default:
        return <ProviderSection />;
    }
  };

  const handlePresaleAccess = () => {
    setShowPresaleAccess(true);
  };

  const handleCloseOverlay = () => {
    setShowPresaleAccess(false);
  };

  console.log('App.js - Using network:', network);
  console.log('App.js - Endpoint:', endpoint);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <div className="App min-h-screen bg-gradient-to-r from-blue-500 to-yellow-400">
            <Navbar />
            <PresaleNotification onAccessClick={handlePresaleAccess} />
            <div className="container mx-auto px-4">
              <PresaleTracker />
              <HeroSection activeSection={activeSection} setActiveSection={setActiveSection} renderComponent={renderComponent} />
            </div>
            {showPresaleAccess && <PresaleAccessOverlay onClose={handleCloseOverlay} />}
          </div>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;