import React, { useState } from 'react';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

const PresaleAccessOverlay = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showRegistration, setShowRegistration] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const handleAccess = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://soloracles.xyz/api/presale-access/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        // Redirect to dashboard or show success message
        onClose();
      } else if (data.status === 'not_registered') {
        setShowRegistration(true);
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.error('Error accessing presale:', error);
      setErrorMessage('Access failed. Please try again.');
    }
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    if (!hasAgreed) {
      setErrorMessage('You must agree to the Privacy Policy and Terms of Service');
      return;
    }
    try {
      const response = await fetch('https://soloracles.xyz/api/register/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        // Redirect to dashboard or show success message
        onClose();
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.error('Error registering user:', error);
      setErrorMessage('Registration failed. Please try again.');
    }
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      showRegistration ? handleRegistration(e) : handleAccess(e);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <h2 className="text-2xl font-bold mb-4 text-soloracles-blue text-center">
          {showRegistration ? 'Register for Presale' : 'Access Presale'}
        </h2>
        <button 
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors duration-200"
          onClick={onClose}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {errorMessage && <p className="text-red-500 mb-4 text-center">{errorMessage}</p>}
        <form onSubmit={showRegistration ? handleRegistration : handleAccess} className="space-y-4">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
            required
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-soloracles-blue"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            required
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-soloracles-blue"
          />
          {showRegistration && (
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="agree"
                checked={hasAgreed}
                onChange={(e) => setHasAgreed(e.target.checked)}
                required
                className="form-checkbox text-soloracles-blue"
              />
              <label htmlFor="agree" className="text-sm">
                I agree to the{' '}
                <button type="button" onClick={() => setShowPrivacyPolicy(true)} className="text-soloracles-blue hover:underline">Privacy Policy</button>{' '}
                and{' '}
                <button type="button" onClick={() => setShowTermsOfService(true)} className="text-soloracles-blue hover:underline">Terms of Service</button>
              </label>
            </div>
          )}
          <div className="flex justify-center">
            <button type="submit" className="bg-soloracles-blue text-white px-6 py-2 rounded hover:bg-opacity-90 transition-colors">
              {showRegistration ? 'Register' : 'Access'}
            </button>
          </div>
        </form>
        {!showRegistration && (
          <p className="mt-4 text-sm text-gray-600 text-center">
            Don't have an account?{' '}
            <button onClick={() => setShowRegistration(true)} className="text-soloracles-blue hover:underline">
              Register here
            </button>
          </p>
        )}
      </div>
      {showPrivacyPolicy && (
        <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)} />
      )}
      {showTermsOfService && (
        <TermsOfService onClose={() => setShowTermsOfService(false)} />
      )}
    </div>
  );
};

export default PresaleAccessOverlay;