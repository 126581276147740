import React, { useState } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-white bg-opacity-80 shadow-lg w-full flex justify-between items-center px-4 sm:px-6 lg:px-8 py-2">
      <div className="flex items-center space-x-8">
        <img src="./logo.png" alt="SolOracles" className="h-12 w-auto transition-opacity duration-300" />
        <ul className={`hidden md:flex space-x-6 items-center`}>
          <li><a href="https://www.soloracles.xyz/" className="text-blue-900 font-bold hover:text-yellow-500 transition-colors duration-300">Home</a></li>
          <li><a href="https://docs.soloracles.xyz/" target="_blank" rel="noopener noreferrer" className="text-blue-900 font-bold hover:text-yellow-500 transition-colors duration-300">Whitepaper</a></li>
          <li><a href="https://docs.soloracles.xyz/community/telegram" target="_blank" rel="noopener noreferrer" className="text-blue-900 font-bold hover:text-yellow-500 transition-colors duration-300">Community</a></li>
        </ul>
      </div>
      
      <div className="flex items-center">
        <div className="hidden md:block">
          <WalletMultiButton className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded" />
        </div>
        <button className="md:hidden ml-4" onClick={toggleMenu}>
          <div className="flex flex-col items-center">
            <div className="w-6 h-0.5 bg-blue-900 mb-1"></div>
            <div className="w-6 h-0.5 bg-blue-900 mb-1"></div>
            <div className="w-6 h-0.5 bg-blue-900"></div>
          </div>
        </button>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="absolute top-full left-0 right-0 bg-white shadow-md md:hidden">
          <ul className="flex flex-col items-center py-4 space-y-4">
            <li><a href="https://www.soloracles.xyz/" className="text-blue-900 font-bold hover:text-yellow-500 transition-colors duration-300">Home</a></li>
            <li><a href="https://docs.soloracles.xyz/" target="_blank" rel="noopener noreferrer" className="text-blue-900 font-bold hover:text-yellow-500 transition-colors duration-300">Whitepaper</a></li>
            <li><a href="https://docs.soloracles.xyz/community/telegram" target="_blank" rel="noopener noreferrer" className="text-blue-900 font-bold hover:text-yellow-500 transition-colors duration-300">Community</a></li>
            <li>
              <WalletMultiButton className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded" />
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;