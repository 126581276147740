// src/index.js
import './tailwind.css';
import { Buffer } from 'buffer';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import process from 'process';

window.Buffer = Buffer;
window.process = process;

// Polyfill process and Buffer
if (!window.process) window.process = {}
if (!window.process.env) window.process.env = {}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);