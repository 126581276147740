import React, { useState, useEffect, useCallback } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { ComputeBudgetProgram, PublicKey, Transaction, TransactionInstruction, TransactionMessage, VersionedTransaction, SystemProgram, SYSVAR_RENT_PUBKEY } from '@solana/web3.js';
import { Token, TOKEN_PROGRAM_ID, getAssociatedTokenAddress, getAccount } from '@solana/spl-token';
import BN from 'bn.js';
import ErrorPopup from './ErrorPopup';

const ProviderSection = () => {
  const { connection } = useConnection();
  const { connected, publicKey, sendTransaction, signTransaction } = useWallet();
  const [providerStats, setProviderStats] = useState({
   participatingPrograms: 0,
   currentEarnings: 0,
   nextCall: null,
  });
  const [availableOracles, setAvailableOracles] = useState([]);
  const [selectedOracle, setSelectedOracle] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userBalance, setUserBalance] = useState(0);
  const [isRegistered, setIsRegistered] = useState(false);
  const [registrationFee, setRegistrationFee] = useState(122);
  const [transactionFee, setTransactionFee] = useState(0.000005);
  const [successMessage, setSuccessMessage] = useState(null);
  const oraclesPerPage = 5;
  
  async function sha256(message) {
    const msgBuffer = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    return new Uint8Array(hashBuffer);
  }

  // Constants
  const PROGRAM_ID = new PublicKey('6MpwBJeZ76HxfsYeYKapBPEhCBbUZvSDwDNEqYCJNeXH');

  useEffect(() => {
    console.log('ProviderSection - Current network:', connection.rpcEndpoint);
  }, [connection]);
  
  useEffect(() => {
    const checkRegistrationStatus = async () => {
      if (connected && publicKey) {
        const storedStatus = localStorage.getItem(`providerRegistered_${publicKey.toBase58()}`);
        if (storedStatus === 'true') {
          setIsRegistered(true);
        } else {
          const status = await checkIfUserIsRegistered(publicKey, connection);
          setIsRegistered(status);
          localStorage.setItem(`providerRegistered_${publicKey.toBase58()}`, status.toString());
        }
      }
    };

    checkRegistrationStatus();
  }, [connected, publicKey, connection]);

  useEffect(() => {
    const fetchOracles = async () => {
      // This should be replaced with an actual API call to your backend
      const mockOracles = Array.from({ length: 20 }, (_, i) => ({
        id: `${i + 1}`,
        name: `Oracle ${i + 1}`,
        pricePerCall: (Math.random() * 0.1 + 0.01).toFixed(3),
        activeProviders: Math.floor(Math.random() * 10) + 1,
      }));
      setAvailableOracles(mockOracles);
    };

    fetchOracles();
  }, []);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (connected && publicKey) {
        try {
          const [tokenMintPDA] = await PublicKey.findProgramAddress(
            [Buffer.from('pda_token_mint')],
            PROGRAM_ID
          );
          const userTokenAccount = await getAssociatedTokenAddress(
            tokenMintPDA,
            publicKey
          );
          let accountInfo;
          try {
            accountInfo = await getAccount(connection, userTokenAccount);
          } catch (error) {
            // If the account doesn't exist, set balance to 0
            accountInfo = { amount: 0 };
          }
          const balance = Number(accountInfo.amount);
          setUserBalance(balance);
          
          // Check if user is registered
          const registered = await checkIfUserIsRegistered(publicKey, connection);
          setIsRegistered(registered);

          if (balance < registrationFee && !registered) {
            setErrorMessage(`You do not hold enough SolOracle tokens to register. You need at least ${registrationFee} tokens.`);
          } else {
            setErrorMessage(null);
          }
        } catch (error) {
          console.error('Error checking user status:', error);
          setErrorMessage('Failed to check your account status. Please try again.');
        }
      }
    };

    checkUserStatus();
  }, [connected, publicKey, connection, registrationFee]);

const handleProviderRegistration = useCallback(async () => {
  if (!publicKey || !signTransaction) {
    setErrorMessage('Wallet not connected or does not support transaction signing!');
    return;
  }
  setIsLoading(true);
  setErrorMessage(null);

  try {
    console.log("Starting provider registration...");
    console.log("User balance:", userBalance);
    console.log("Registration fee:", registrationFee);
    console.log("Wallet connected:", connected);
    console.log("Public key:", publicKey?.toBase58());

    if (userBalance < registrationFee) {
      throw new Error(`Insufficient balance. You need at least ${registrationFee} tokens.`);
    }

    const [tokenMintPDA] = await PublicKey.findProgramAddress(
      [Buffer.from('pda_token_mint')],
      PROGRAM_ID
    );
	
    const userTokenAccount = await getAssociatedTokenAddress(
      tokenMintPDA,
      publicKey
    );

    const seed = `stake_provider_${publicKey.toBase58()}`;
    const hash = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(seed));
    const seedPrefix = `sp${Buffer.from(hash).slice(0, 15).toString('hex')}`;

    const [stakeAccountPDA] = await PublicKey.findProgramAddress(
      [Buffer.from('stake'), Buffer.from(seedPrefix)],
      PROGRAM_ID
    );

    const [customDataAccountPDA] = await PublicKey.findProgramAddress(
      [Buffer.from('data'), Buffer.from(seedPrefix)],
      PROGRAM_ID
    );

    // Dummy PDA for oracle token mint (not used for providers)
    const [dummyOracleTokenMintPDA] = await PublicKey.findProgramAddress(
      [Buffer.from('dummy_oracle_token_mint')],
      PROGRAM_ID
    );

    // Dummy PDA for oracle token account (not used for providers)
    const [dummyOracleTokenAccountPDA] = await PublicKey.findProgramAddress(
      [Buffer.from('dummy_oracle_token_account')],
      PROGRAM_ID
    );
	
    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext();

    const instructionData = Buffer.alloc(42); // 1 + 32 + 8 + 1
    instructionData.writeUInt8(1, 0); // Instruction index
    const stakeTypeBuffer = Buffer.from("stake_provider".padEnd(32, '\0'));
    stakeTypeBuffer.copy(instructionData, 1);
    const registrationFeeBN = new BN(registrationFee);
    registrationFeeBN.toArrayLike(Buffer, 'le', 8).copy(instructionData, 33);
    // Add a byte to indicate None for oracle_data
    instructionData.writeUInt8(0, 41);

    const additionalComputeBudgetInstruction = ComputeBudgetProgram.setComputeUnitLimit({ 
      units: 400_000 
    });

    const mainInstruction = new TransactionInstruction({
      keys: [
        { pubkey: publicKey, isSigner: true, isWritable: true },
        { pubkey: userTokenAccount, isSigner: false, isWritable: true },
        { pubkey: stakeAccountPDA, isSigner: false, isWritable: true },
        { pubkey: customDataAccountPDA, isSigner: false, isWritable: true },
        { pubkey: tokenMintPDA, isSigner: false, isWritable: false },
        { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
        { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false },
        { pubkey: dummyOracleTokenMintPDA, isSigner: false, isWritable: false },
        { pubkey: dummyOracleTokenAccountPDA, isSigner: false, isWritable: false },
      ],
      programId: PROGRAM_ID,
      data: instructionData,
    });

    const messageV0 = new TransactionMessage({
      payerKey: publicKey,
      recentBlockhash: blockhash,
      instructions: [additionalComputeBudgetInstruction, mainInstruction]
    }).compileToV0Message();

    const transaction = new VersionedTransaction(messageV0);

    console.log("Transaction created:", transaction);
    console.log("Transaction instructions:", transaction.instructions);

    console.log('Signing transaction...');
    let signedTransaction = await signTransaction(transaction);
    console.log('Transaction signed successfully');

    console.log('Sending signed transaction...');
    let signature = await connection.sendRawTransaction(signedTransaction.serialize(), {
      skipPreflight: false,
      preflightCommitment: 'confirmed'
    });
    console.log('Transaction sent. Signature:', signature);

    console.log('Confirming transaction...');
    const confirmationResult = await connection.confirmTransaction({ 
      signature, 
      blockhash, 
      lastValidBlockHeight 
    });

    if (confirmationResult.value.err) {
      throw new Error(`Transaction failed: ${JSON.stringify(confirmationResult.value.err)}`);
    }

    setSuccessMessage('Provider registered successfully!');
    setIsRegistered(true);
    localStorage.setItem(`providerRegistered_${publicKey.toBase58()}`, 'true');
    setUserBalance(prevBalance => prevBalance - registrationFee);
  } catch (error) {
    console.error('Error registering provider:', error);
    setErrorMessage(`Failed to register as provider: ${error.message}`);
  } finally {
    setIsLoading(false);
  }
}, [publicKey, signTransaction, connection, userBalance, registrationFee]);

  const handleOracleRegistration = useCallback(async (oracle) => {
    if (!publicKey || !signTransaction) {
      setErrorMessage('Wallet not connected or does not support transaction signing!');
      return;
    }
    setIsLoading(true);
    setErrorMessage(null);

    try {
      console.log(`Registering for Oracle: ${oracle.name}`);
      // Placeholder for oracle registration logic
      // This is where you would implement the actual registration process
      
      setSuccessMessage(`Successfully registered for Oracle: ${oracle.name}`);
    } catch (error) {
      console.error('Error registering for oracle:', error);
      setErrorMessage(`Failed to register for oracle: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [publicKey, signTransaction]);

  const checkIfUserIsRegistered = async (publicKey, connection) => {
    const seed = `stake_provider_${publicKey.toBase58()}`;
    const hash = await sha256(Buffer.from(seed));
    const seedPrefix = `sp${Buffer.from(hash).slice(0, 15).toString('hex')}`;

    const [stakeAccountPDA] = await PublicKey.findProgramAddress(
      [Buffer.from('stake'), Buffer.from(seedPrefix)],
      PROGRAM_ID
    );
      
    try {
      const accountInfo = await connection.getAccountInfo(stakeAccountPDA);
      return accountInfo !== null;
    } catch (error) {
      console.error('Error checking registration status:', error);
      return false;
    }
  };

  const indexOfLastOracle = currentPage * oraclesPerPage;
  const indexOfFirstOracle = indexOfLastOracle - oraclesPerPage;
  const currentOracles = availableOracles.slice(indexOfFirstOracle, indexOfLastOracle);
  const totalPages = Math.ceil(availableOracles.length / oraclesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const fetchProviderStats = async () => {
      if (isRegistered && publicKey) {
        // This should be replaced with actual API calls to fetch provider stats
        setProviderStats({
          participatingPrograms: Math.floor(Math.random() * 5) + 1,
          currentEarnings: (Math.random() * 100).toFixed(2),
          nextCall: new Date(Date.now() + Math.random() * 86400000).toISOString(),
        });
      }
    };

    fetchProviderStats();
  }, [isRegistered, publicKey]);

  const truncateAddress = (address) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  return (
    <div className="col-span-4 lg:col-span-3 w-full">
      <div className="flex flex-col w-full max-w-[95vw] sm:max-w-[90vw] md:max-w-[80vw] lg:max-w-[70vw] mx-auto min-h-[40rem]">
        <div className="w-full h-fit rounded-3xl px-4 xs:px-5 py-5 sm:px-8 sm:py-9 shadow-lg border border-light-gray bg-white/20 text-white">
          <h1 className="text-3xl font-medium mb-4">Provider Dashboard</h1>
          
          {!connected ? (
            <div className="w-full mt-4">
              <WalletMultiButton 
                className="custom-wallet-adapter-button !bg-gradient-to-r !from-blue-500 !to-purple-500 !w-full !py-4 !px-6 !rounded-full !text-white !font-bold !text-xl !h-auto !transition-all !duration-200 hover:!from-blue-600 hover:!to-purple-600"
              />
            </div>
          ) : !isRegistered ? (
            <div className="w-full mt-4">
              <button 
                className="custom-wallet-adapter-button !bg-gradient-to-r !from-blue-500 !to-purple-500 !w-full !py-4 !px-6 !rounded-full !text-white !font-bold !text-xl !h-auto !transition-all !duration-200 hover:!from-blue-600 hover:!to-purple-600"
                onClick={handleProviderRegistration}
                disabled={isLoading}
              >
                {isLoading ? 'Registering...' : 'Register as Provider'}
              </button>
              
              <div className="mt-4 text-sm text-white/80">
                <div className="flex justify-between mb-2">
                  <span>Registration fee:</span>
                  <span>{registrationFee} SolOracle tokens</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Transaction fee:</span>
                  <span>{transactionFee} SOL</span>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="bg-white/10 rounded-xl p-4 mb-8">
                <h2 className="text-2xl font-medium mb-2">Welcome, {truncateAddress(publicKey.toBase58())}</h2>
                <p>Participating Programs: {providerStats.participatingPrograms}</p>
                <p>Current Earnings: {providerStats.currentEarnings} SOL</p>
              </div>

              <div className="w-full">
                <h2 className="text-2xl font-medium mb-4">Available Oracles</h2>
                <div>
                  {currentOracles.map((oracle) => (
                    <div 
                      key={oracle.id} 
                      className="bg-white/10 rounded-xl p-4 mb-4 hover:bg-white/20 transition-all duration-200 cursor-pointer flex justify-between items-center"
                      onClick={() => setSelectedOracle(oracle)}
                    >
                      <div>
                        <h3 className="text-lg font-medium">{oracle.name}</h3>
                        <p>Price per call: {oracle.pricePerCall} SOL</p>
                        <p>Active providers: {oracle.activeProviders}</p>
                      </div>
                      <button
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-all duration-200"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOracleRegistration(oracle);
                        }}
                      >
                        Register
                      </button>
                    </div>
                  ))}
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i}
                      onClick={() => paginate(i + 1)}
                      className={`mx-1 px-3 py-1 rounded ${
                        currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white/10'
                      }`}
                    >
                      {i + 1}
                    </button>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {errorMessage && (
        <ErrorPopup 
          message={errorMessage} 
          onClose={() => setErrorMessage(null)} 
        />
      )}
      {successMessage && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg">
            <p className="text-green-600 font-bold">{successMessage}</p>
            <button 
              onClick={() => setSuccessMessage(null)}
              className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProviderSection;